import classNames from 'classnames';
import { FileImageSizes } from '~/.server/drizzle/entity/FileEntity';

export type ResizedThumbnailSizes = Pick<
  FileImageSizes,
  'small_1x' | 'small_2x' | 'small_3x' | 'medium_1x' | 'medium_2x' | 'medium_3x'
>;

export const RESIZED_THUMBNAIL_WIDTH = 192;
export const RESIZED_THUMBNAIL_HEIGHT = 128;

export default function ResizedThumbnail(props: {
  file: ResizedThumbnailSizes;
  onClick?: () => void;
  width?: number;
  height?: number;
  className?: string;
  round?: boolean;
  medium?: boolean;
  fit?: boolean;
}): JSX.Element {
  const small = `${props.file.small_1x}, ${props.file.small_2x} 2x, ${props.file.small_3x} 3x`;
  const medium = `${props.file.medium_1x}, ${props.file.medium_2x} 2x, ${props.file.medium_3x} 3x`;

  return (
    <img
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      srcSet={props.medium ? medium : small}
      width={props.width ?? (props.className ? undefined : RESIZED_THUMBNAIL_WIDTH)}
      height={props.height ?? (props.className ? undefined : RESIZED_THUMBNAIL_HEIGHT)}
      style={{ width: props.width, height: props.height }}
      onClick={props.onClick}
      className={classNames(props.className, {
        'object-cover': !props.fit,
        'object-contain border border-theme-outline': props.fit,
        'rounded-theme overflow-hidden': props.round,
      })}
    />
  );
}
